// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-christina-connelly-js": () => import("./../../../src/pages/about-us/christina-connelly.js" /* webpackChunkName: "component---src-pages-about-us-christina-connelly-js" */),
  "component---src-pages-about-us-gayle-voight-block-js": () => import("./../../../src/pages/about-us/gayle-voight-block.js" /* webpackChunkName: "component---src-pages-about-us-gayle-voight-block-js" */),
  "component---src-pages-about-us-havish-sarma-js": () => import("./../../../src/pages/about-us/havish-sarma.js" /* webpackChunkName: "component---src-pages-about-us-havish-sarma-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-about-us-mary-szymaszek-js": () => import("./../../../src/pages/about-us/mary-szymaszek.js" /* webpackChunkName: "component---src-pages-about-us-mary-szymaszek-js" */),
  "component---src-pages-about-us-shreyas-desai-js": () => import("./../../../src/pages/about-us/shreyas-desai.js" /* webpackChunkName: "component---src-pages-about-us-shreyas-desai-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/blog/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-blog-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-book-appointment-current-patient-js": () => import("./../../../src/pages/book-appointment/current-patient.js" /* webpackChunkName: "component---src-pages-book-appointment-current-patient-js" */),
  "component---src-pages-book-appointment-new-patient-js": () => import("./../../../src/pages/book-appointment/new-patient.js" /* webpackChunkName: "component---src-pages-book-appointment-new-patient-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-1-js": () => import("./../../../src/pages/landing/1.js" /* webpackChunkName: "component---src-pages-landing-1-js" */),
  "component---src-pages-locations-js": () => import("./../../../src/pages/locations.js" /* webpackChunkName: "component---src-pages-locations-js" */),
  "component---src-pages-services-geriatrics-js": () => import("./../../../src/pages/services/geriatrics.js" /* webpackChunkName: "component---src-pages-services-geriatrics-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-pediatrics-js": () => import("./../../../src/pages/services/pediatrics.js" /* webpackChunkName: "component---src-pages-services-pediatrics-js" */),
  "component---src-pages-services-prenatal-js": () => import("./../../../src/pages/services/prenatal.js" /* webpackChunkName: "component---src-pages-services-prenatal-js" */),
  "component---src-pages-services-preventive-care-js": () => import("./../../../src/pages/services/preventive-care.js" /* webpackChunkName: "component---src-pages-services-preventive-care-js" */),
  "component---src-pages-services-primary-care-js": () => import("./../../../src/pages/services/primary-care.js" /* webpackChunkName: "component---src-pages-services-primary-care-js" */),
  "component---src-pages-services-suboxone-clinic-js": () => import("./../../../src/pages/services/suboxone-clinic.js" /* webpackChunkName: "component---src-pages-services-suboxone-clinic-js" */)
}

